










import Vue from "vue";
import moment from "moment";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import DataContainer from "@vuetifyx/common/DataContainer";

export default Vue.extend({
  data() {
    return {
      self: this,
      tableOptions: false,
    };
  },
  async created() {
    const market = await coreApiClient.call("markets", "findById", {
      id: this.$route.params.marketId,
    });
    const setting = await coreApiClient.call(
      "setting",
      "get",
      undefined,
      undefined
    );
    this.tableOptions = this.makeTableOptions(this, market, setting);
  },
  methods: {
    makeTableOptions(that, market, setting) {
      return {
        attrs: {
          "item-key": "_id",
          "sort-by": "time",
          "sort-desc": true,
          "show-select": true,
        },
        content: {
          name: "Quản lý chi phí MKT",
          urlEnabled: true,
          search: {},
          filters: {
            colLength: 4,
            filters: {
              userId: {
                type: "XAutocomplete",
                attrs: {
                  label: "User MKT",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  "item-disabled": "_",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call(
                          "users",
                          "findAll",
                          {
                            payload: JSON.stringify({
                              limit,
                              search,
                              filters: [
                                {
                                  key: "_id",
                                  operator: "equal_to",
                                  value,
                                },
                              ],
                            }),
                          }
                        );
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              time: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Thời gian",
                },
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Thời gian (bắt đầu)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.startOf("day"),
                        },
                      },
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Thời gian (kết thúc)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.endOf("day"),
                        },
                      },
                    },
                  },
                },
              },
              xShopId: market.tmdt && {
                type: "XAutocomplete",
                attrs: {
                  label: "MKT Link",
                  "item-value": "_id",
                  "item-text": "name",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call(
                          "shops",
                          "findAll",
                          {
                            payload: JSON.stringify({
                              limit,
                              search,
                              filters: [
                                {
                                  key: "marketId",
                                  operator: "equal_to",
                                  value: market._id,
                                },
                                {
                                  key: "_id",
                                  operator: "equal_to",
                                  value,
                                },
                              ],
                            }),
                          }
                        );
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              productGroupId: market.rd && {
                type: "XAutocomplete",
                attrs: {
                  label: "Nhóm sản phẩm",
                  "item-value": "_id",
                  "item-text": "sku",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call(
                          "productGroups",
                          "findAll",
                          {
                            payload: JSON.stringify({
                              limit,
                              search,
                              filters: [
                                {
                                  key: "countryId",
                                  operator: "equal_to",
                                  value: market.countryId,
                                },
                                {
                                  key: "_id",
                                  operator: "equal_to",
                                  value,
                                },
                              ],
                            }),
                          }
                        );
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
            },
          },
          displayFields: {
            user: {
              text: "User",
              options: {
                subProp: "user.name",
                sortBy: "userId",
                attrs(_, item) {
                  const attrs: any = {};
                  if (item.user && item.user.ht === "nv") {
                    attrs.class = "red--text";
                  }
                  return attrs;
                },
              },
            },
            team: {
              text: "Team",
              options: {
                subProp: "team.name",
                sortBy: "teamId",
              },
            },
            department: {
              text: "Phòng",
              options: {
                subProp: "department.name",
                sortBy: "departmentId",
              },
            },
            qcCost: !market.tmdt && {
              text: "Chi phí quảng cáo",
              sortable: true,
              options: {
                filter: "currency",
                transform(value) {
                  return `${value} VNĐ`;
                },
              },
            },
            internalQcCost: market.tmdt && {
              text: "Chi phí quảng cáo nội sàn",
              sortable: true,
              options: {
                filter: "currency",
                transform(value) {
                  return `${value} VNĐ`;
                },
              },
            },
            externalQcCost: market.tmdt && {
              text: "Chi phí quảng cáo ngoại sàn",
              sortable: true,
              options: {
                filter: "currency",
                transform(value) {
                  return `${value} VNĐ`;
                },
              },
            },
            nlCost: {
              text: "Chi phí nguyên liệu",
              sortable: true,
              options: {
                filter: "currency",
                transform(value) {
                  return `${value} VNĐ`;
                },
              },
            },
            agencyCost: {
              text: "Chi phí agency",
              sortable: true,
              options: {
                filter: "currency",
                transform(value) {
                  return `${value} VNĐ`;
                },
              },
            },
            otherCost: {
              text: "Chi phí khác",
              sortable: true,
              options: {
                filter: "currency",
                transform(value) {
                  return `${value} VNĐ`;
                },
              },
            },
            shop: market.tmdt && {
              text: "Shop",
              options: {
                subProp: "shop.name",
                sortBy: "xShopId",
              },
            },
            productGroup: market.rd && {
              text: "Nhóm sản phẩm",
              options: {
                subProp: "productGroup.sku",
                sortBy: "productGroupId",
              },
            },
            description: {
              text: "Mô tả",
            },
            time: {
              text: "Thời gian",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            action: {},
          },
          topActionButtons: {
            export: userManager.checkRole([
              "kt",
              "mkt",
              "tp",
              "rd",
              "rdl",
              "ksnb",
              "ksnbl",
            ]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Xuất Excel",
                icon: "mdi-microsoft-excel",
              },
              on: {
                xClick({ self }) {
                  const dataTable = self.context();
                  let findAllOptions = dataTable.getFindAllOptions();
                  if (dataTable.model.value.length) {
                    findAllOptions = {
                      payload: JSON.stringify({
                        filters: [
                          {
                            key: "_id",
                            operator: "in",
                            value: dataTable.model.value.map(
                              (item) => item._id
                            ),
                          },
                        ],
                        limit: -1,
                      }),
                    };
                  }
                  coreApiClient.download(
                    "mktCosts",
                    `@/export`,
                    findAllOptions
                  );
                },
              },
            },
            exportDaily: userManager.checkRole([
              "kt",
              "mkt",
              "tp",
              "rd",
              "rdl",
              "ksnb",
              "ksnbl",
            ]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Xuất Daily",
                icon: "mdi-calendar",
              },
              target: {
                dialog: {
                  handlers: {
                    initialize() {
                      this.options.content.buttons.save.states.enabled =
                        new DataContainer(false);
                    },
                  },
                  content: {
                    toolbar: {
                      title: "Xuất Daily",
                    },
                    content: {
                      type: "XForm",
                      makeAttrs(attrs, dialog) {
                        attrs.xModel =
                          dialog.options.content.buttons.save.states.enabled;
                        attrs.xContext = dialog;
                        attrs.xData = new DataContainer({});
                        return attrs;
                      },
                      attrs: {
                        xOptions: {
                          content: {
                            sections: {
                              default: {
                                fields: {
                                  from: {
                                    type: "XDateTimePicker",
                                    attrs: {
                                      label: "From",
                                      required: true,
                                    },
                                  },
                                  to: {
                                    type: "XDateTimePicker",
                                    attrs: {
                                      label: "To",
                                      required: true,
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                    buttons: {
                      save: {
                        content: {
                          text: "Tải xuống",
                        },
                        states: {},
                        on: {
                          async xClick({ self }) {
                            const dialog = self.context();
                            const form = dialog.contentContainer.value;
                            const data = form.getData();
                            coreApiClient.download(
                              "mktCosts",
                              `@/exportDaily`,
                              {
                                marketId: that.$route.params.marketId,
                                from: moment(data.from)
                                  .startOf("day")
                                  .valueOf(),
                                to: moment(data.to).endOf("day").valueOf(),
                              }
                            );
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["kt", "mkt", "tp", "rd", "rdl"]) && {
                target: {
                  dialog: {
                    ext: {
                      subTitleMaker: "name",
                    },
                  },
                },
                ext: {
                  makeAttrs(item) {
                    const attrs = {
                      xDisabled: new DataContainer(
                        setting.mktCostLockTime &&
                          item.time < setting.mktCostLockTime
                      ),
                    };
                    return attrs;
                  },
                },
              },
              delete: userManager.checkRole([
                "kt",
                "mkt",
                "tp",
                "rd",
                "rdl",
              ]) && {
                ext: {
                  makeAttrs(item) {
                    const attrs = {
                      xDisabled: new DataContainer(
                        setting.mktCostLockTime &&
                          item.time < setting.mktCostLockTime
                      ),
                    };
                    return attrs;
                  },
                },
              },
            },
          },
          findAllOptionsFilter(options) {
            options.filters.push({
              key: "marketId",
              operator: "equal_to",
              value: this.context().$route.params.marketId,
            });
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call(
                "mktCosts",
                "findAll",
                options
              );
              return [items, count];
            },
            async insert(item, dataTable) {
              item.marketId = dataTable.context().$route.params.marketId;
              return await coreApiClient.call(
                "mktCosts",
                "create",
                undefined,
                item
              );
            },
            async update(item) {
              return await coreApiClient.call(
                "mktCosts",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("mktCosts", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              qcCost: {
                type: "number",
                attrs: {
                  label: "Chi phí quảng cáo",
                  "prepend-icon": "mdi-currency-usd",
                  suffix: "VNĐ",
                },
                ext: {
                  condition() {
                    return !market.tmdt;
                  },
                },
              },
              internalQcCost: {
                type: "number",
                attrs: {
                  label: "Chi phí quảng cáo nội sàn",
                  "prepend-icon": "mdi-currency-usd",
                  suffix: "VNĐ",
                },
                ext: {
                  condition() {
                    return market.tmdt;
                  },
                },
              },
              externalQcCost: {
                type: "number",
                attrs: {
                  label: "Chi phí quảng cáo ngoại sàn",
                  "prepend-icon": "mdi-currency-usd",
                  suffix: "VNĐ",
                },
                ext: {
                  condition() {
                    return market.tmdt;
                  },
                },
              },
              nlCost: {
                type: "number",
                attrs: {
                  label: "Chi phí nguyên liệu",
                  "prepend-icon": "mdi-currency-usd",
                  suffix: "VNĐ",
                },
              },
              agencyCost: {
                type: "number",
                attrs: {
                  label: "Chi phí agency",
                  "prepend-icon": "mdi-currency-usd",
                  suffix: "VNĐ",
                },
              },
              otherCost: {
                type: "number",
                attrs: {
                  label: "Chi phí khác",
                  "prepend-icon": "mdi-currency-usd",
                  suffix: "VNĐ",
                },
              },
              time: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Thời gian",
                },
                ext: {
                  required: true,
                  defaultValue: new Date().getTime(),
                },
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Mô tả",
                },
              },
              xShopId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Shop",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call(
                          "shops",
                          "findAll",
                          {
                            payload: JSON.stringify({
                              limit,
                              search,
                              filters: [
                                {
                                  key: "marketId",
                                  operator: "equal_to",
                                  value: market._id,
                                },
                                {
                                  key: "_id",
                                  operator: "equal_to",
                                  value,
                                },
                              ],
                            }),
                          }
                        );
                        return items;
                      },
                    },
                  },
                },
                ext: {
                  condition() {
                    return market.tmdt;
                  },
                },
              },
              productGroupId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Nhóm sản phẩm",
                  required: true,
                  "item-value": "_id",
                  "item-text": "sku",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call(
                          "productGroups",
                          "findAll",
                          {
                            payload: JSON.stringify({
                              limit,
                              search,
                              filters: [
                                {
                                  key: "countryId",
                                  operator: "equal_to",
                                  value: market.countryId,
                                },
                                {
                                  key: "_id",
                                  operator: "equal_to",
                                  value,
                                },
                              ],
                            }),
                          }
                        );
                        return items;
                      },
                    },
                  },
                },
                ext: {
                  condition() {
                    return market.rd;
                  },
                },
              },
            },
            insertForm: userManager.checkRole([
              "kt",
              "mkt",
              "tp",
              "rd",
              "rdl",
            ]) && {
              content: {
                fieldNames: [
                  "qcCost",
                  "internalQcCost",
                  "externalQcCost",
                  "nlCost",
                  "agencyCost",
                  "otherCost",
                  "time",
                  "xShopId",
                  "productGroupId",
                  "description",
                ],
              },
            },
            editForm: userManager.checkRole([
              "kt",
              "mkt",
              "tp",
              "rd",
              "rdl",
            ]) && {
              content: {
                fieldNames: [
                  "description",
                  "xShopId",
                  "productGroupId",
                  "time",
                  "qcCost",
                  "internalQcCost",
                  "externalQcCost",
                  "nlCost",
                  "agencyCost",
                  "otherCost",
                ],
              },
            },
            delete: userManager.checkRole(["kt", "mkt", "tp", "rd", "rdl"]) && {
              confirmDisplayField: "time",
            },
          },
        },
      };
    },
  },
});
